#root {
  height: 100vh;
}

#Main {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--gradient);
  overflow: auto;
}

#Header *:not(img), #Content * {
  transition: 0.3s ease-in-out;
}

.HeaderSlide {
  transform: translateY(-150%);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .Logo {
    animation: spin infinite 20s linear;
  }
}
