:root {
  --primary: #0052CC;
  --gradient: #0052CC radial-gradient(circle at 0% 0%,#0030AA 0,#0052CC 100%) repeat scroll 0 0;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: white rgba(0, 0, 0, 0.2);
}

:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 #000 !important;
}

::-moz-focus-inner {
  border: none;
  outline: none;
}

::-ms-clear {
  display: none;
}

::-webkit-scrollbar {
  width: 0.75vw;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ddd;
  border-radius: 0;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #0074EE;
}

@-moz-document url-prefix() {
  select, select:-moz-focusring, select::-moz-focus-inner {
     color: transparent !important;
     text-shadow: 0 0 0 #000 !important;
     background-image: none !important;
     border-bottom: 0.2em solid #E6E6E6;
  }
}